import React from 'react'
import { Link } from 'gatsby'

interface Props {
  title: string
  location: Location
}

const Navibar: React.FC<Props> = ({ location, title }: Props) => {
  return (
    <nav className="navbar navbar-expand navbar-light bg-light fixed-top flex-column flex-md-row pr-md-0">
      <div id="navContainer" className={
        location.pathname === '/'
          ? 'container-fluid front'
          : 'container-fluid'
      }
      >
        <Link className="text-center" to="/" replace>
          <img className="img-responsive mx-auto d-block" src="/cbi_logo.svg" alt="Center for Building Innovation (CBI)" />
        </Link>
        <div className="navbar-nav-scroll w-100">
          <div className="contact-info flex-row text-right">
            <p>
              <a href="/contact">Contact Us</a> | <a href="https://www.drjengineering.org/careers">Careers</a> | <a href="tel:16083106739">608-310-6739</a>
            </p>
          </div>
          <ul className="navbar-nav bd-navbar-nav flex-row justify-content-center justify-content-md-end">
            <li
              className={
                location.pathname === '/accreditation/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <Link to="/accreditation/" className="nav-link">
                ANAB Accredited
              </Link>
            </li>
            <li
              className={
                location.pathname === '/testing/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <Link to="/testing/" className="nav-link">
                Testing Services
              </Link>
            </li>
            <li
              className={
                location.pathname === '/inspection/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <Link to="/inspection/" className="nav-link">
                Inspection Services
              </Link>
            </li>

            <li
              className={
                location.pathname === '/listings/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <Link to="/listings/" className="nav-link">
                Listings
              </Link>
            </li>

            <li
              className={
                location.pathname === '/about/'
                  ? 'nav-item active'
                  : 'nav-item'
              }
            >
              <Link to="/about/" className="nav-link">
                About Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
      </div>
    </nav>
  )
}

export default Navibar
