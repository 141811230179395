module.exports = {
  siteMetadata: {
    title: 'Center for Building Innovation',
    description: 'CBI is  is a state-of-the-art testing and research facility, specializing in product development, forensic, and R&D testing for a wide array of steel, concrete and wood, structural framing products.',
    siteUrl: 'https://www.cbitest.com',
    author: 'Pushing7',
    twitter: 'PushingSeven',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/posts/`,
        name: 'posts',
      },
    },
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/content/images/`,
        name: 'images',
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: {
        plugins: [
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 1280,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 1.0725rem;',
            },
          },
          {
            resolve: `gatsby-remark-image-attributes`,
            options: {
              // ?Boolean=false
              //   If true, all attributes that
              //   aren't styleAttributes, will be
              //   added as data-* attributes to the
              //   image.
              dataAttributes: false
            }
          },
          {
            resolve: 'gatsby-remark-responsive-iframe',
            options: {
              wrapperStyle: 'margin-bottom: 1.0725rem',
            },
          },
          {
            resolve: `gatsby-source-filesystem`,
            options: {
              path: `./src/data/`,
            },
          },
          {
            resolve: "gatsby-source-custom-api",
            options: {
                url: "https://www.drjcertification.org/api/v1/ter_export",
                rootKey: 'listings',
                schemas: {
                  listings: `
                    title: String
                    alias: String
                    no: String
                    summary: String
                    nid: String
                    issue_date: Date
                    updated_date: Date
                    client_name: String
                    product_name: String
                    sealed_document_id: String
                  `,
                }
            }
          },
          'gatsby-remark-prismjs',
          'gatsby-remark-copy-linked-files',
          'gatsby-remark-smartypants',
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'CBI',
        short_name: 'CBI',
        description: 'Center for Building Innovation',
        start_url: '/',
        background_color: '#fff',
        theme_color: '#000',
        display: 'standalone',
        icons: [
          {
              "src": "/img/android-chrome-192x192.png",
              "sizes": "192x192",
              "type": "image/png"
          },
          {
              "src": "/img/android-chrome-512x512.png",
              "sizes": "512x512",
              "type": "image/png"
          }
      ],
      },
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-image',
    'gatsby-plugin-offline',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    'gatsby-plugin-sharp',
    'gatsby-plugin-sitemap',
    'gatsby-plugin-twitter',
    "gatsby-plugin-typegen",
    'gatsby-plugin-typescript',
    'gatsby-transformer-sharp',
    {
      resolve: `gatsby-plugin-csp`,
      options: {
        disableOnDev: true,
        reportOnly: false, // Changes header to Content-Security-Policy-Report-Only for csp testing purposes
        mergeScriptHashes: true, // you can disable scripts sha256 hashes
        mergeStyleHashes: false, // you can disable styles sha256 hashes
        mergeDefaultDirectives: true,
        directives: {
          "script-src": "'self' 'unsafe-inline' www.google.com www.google-analytics.com www.googletagmanager.com www.gstatic.com use.typekit.net qualtim.us2.list-manage.com fontawesome.io",
          "style-src": "'self' 'unsafe-inline' use.typekit.net qualtim.us2.list-manage.com",
          "img-src": "'self' data: www.google-analytics.com p.typekit.net www.drjcertification.org",
          "frame-src": "'self' www.google.com www.google-analytics.com",
          "font-src": "'self' use.typekit.net",
          "connect-src": "'self' www.google-analytics.com"
          // you can add your directives or override defaults
        }
      }
    },
    {
      resolve: 'gatsby-plugin-mailchimp',
      options: {
          endpoint: 'https://qualtim.us2.list-manage.com/subscribe/post?u=bae1a0bcfcef9c3029f8acc09&amp;id=5b382e4751', // string; add your MC list endpoint here; see instructions below
          timeout: 3500, // number; the amount of time, in milliseconds, that you want to allow mailchimp to respond to your request before timing out. defaults to 3500
      },
    },
    {
      resolve: "gatsby-plugin-web-font-loader",
      options: {
        typekit: {
          id: "nhm0jwz",
        },
      },
    },
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        // You can add multiple tracking ids and a pageview event will be fired for all of them.
        trackingIds: [
          "G-TQ91K42W2X" // Google Analytics / GA
          // "AW-CONVERSION_ID", // Google Ads / Adwords / AW
          // "DC-FLOODIGHT_ID", // Marketing Platform advertising products (Display & Video 360, Search Ads 360, and Campaign Manager)
        ],
        // This object gets passed directly to the gtag config command
        // This config will be shared across all trackingIds
        gtagConfig: {
          optimize_id: "OPT_CONTAINER_ID",
          anonymize_ip: true,
          cookie_expires: 0,
        },
        // This object is used for configuration specific to this plugin
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: false,
          // Setting this parameter is also optional
          respectDNT: true,
          // Avoids sending pageview hits from custom paths
          exclude: ["/preview/**", "/do-not-track/me/too/"],
        },
      },
    },
    `gatsby-plugin-client-side-redirect` // keep this last in list
  ],
}
